// Contentful GraphQL query helpers
// This file contains the GraphQL queries used to retrieve data from Contentful

// Fields applicable to all non-activity content types
const globalContentFields = `
  sys {
    id
  }
  itemTitle
  itemShortDescription
  itemHeroImage {
    url
  }
  itemThumbnailLogo {
    url
  }
  audienceCollection {
    items {
      itemTitle
    }
  }
  tags
`;

const fitnessSpecificFields = `
  grades
  completionTime
  numberOfKids
  shapeStandards
  fitnessFocus
  functionalMovements
  activates
  materialsEquipmentTeacher
  materialsEquipmentKids
  modifications
`;


const fitnessContentFields = `
  ${globalContentFields}
  itemDescription
  ${fitnessSpecificFields}
  printablesCollection(limit: 10) {
    items {
      file {
        fileName
        url
      }
    }
  }
  blastDescription
  activityPartOne {
    json
  }
  activityPartOneTime
  activityPartTwo {
    json
  }
  activityTimePartTwoTime
  activityPartThree {
    json
  }
  activityPartThreeTime
  activityPartFour {
    json
  }
  activityPartFourTime
  objectives
`;



// Non-rich-text fields applicable to all activity content types
const globalActivityFields = `
  itemDescription
  objectives
  activityBanner {
    url
  }
  leadershipSkillsText
  completionTime
  lifeSkills
  printablesCollection(limit: 10) {
    items {
      file {
        fileName
        url
      }
    }
  }
  subscriptionCollection(limit: 10) {
    items {
      subscriptionCategory
    }
  }
  setupTime
  materialsTeacher
  materialsKids
  grades
`;

// GraphQL for rich text fields
const richTextFieldsGQL = `
  json
  links {
    assets {
      block {
        sys {
          id
        }
        url
        title
        width
        height
        description
        contentType
      }
    }
  }
`;

const mediaVideoResourceFragment = `
  itemTitle
  itemShortDescription
  itemThumbnailLogo {
    fileName
    url
  }
  file {
    fileName
    url
  }
`;

// Types of content and their fields for related content and additional resources
const relatedTypesMetaFragment = `
  sys{
    id
  }
  itemTitle
  itemThumbnailLogo {
    url
  }
  itemHeroImage {
    url
  }
  itemShortDescription
`;

// Rich-text fields applicable to all activity content types
const globalActivityRichTextFields = `
  activityPartOne {
    ${richTextFieldsGQL}
  }
  activityPartTwo {
    ${richTextFieldsGQL}
  }
  activityPartThree{
    ${richTextFieldsGQL}
  }
  setupTeacher {
    ${richTextFieldsGQL}
  }
`;

const activityThemeRichTextFields = `
    lifeSkillsCallout1 {
      ${richTextFieldsGQL}
    }
    lifeSkillsReminder2 {
      ${richTextFieldsGQL}
    }
    beforeYouStartText {
      ${richTextFieldsGQL}
    }
    technicallySpeaking1 {
      ${richTextFieldsGQL}
    }
    technicallySpeaking2 {
      ${richTextFieldsGQL}
    }
    technicallySpeaking3 {
      ${richTextFieldsGQL}
    }
    activityPartFour {
      ${richTextFieldsGQL}
    }
    activityPartFive {
      ${richTextFieldsGQL}
    }
    springboardPartOne {
      ${richTextFieldsGQL}
    }
`;

// Type specific fields for content types
const contentTypesFragments = {
  mediaResource: `
    itemDescription
    grades
    url
    resourceType
    allowFileDownload
    file {
      url
      fileName
    }
  `,
  mediaResourceLong: `
    itemDescription
    grades
    resourceType
    fileCollection {
      items {
        url
        fileName
      }
    }
    resourcesBodyContent {
      ${richTextFieldsGQL}
    }
  `,
  routine: `
    why
    grades
    routinePartOne{
      json
    }
    routinePartTwo{
      json
    }
    objectives
    authorCollection {
      items {
        authorName
      }
    }
    subscriptionCollection(limit: 10) {
      items {
        subscriptionCategory
      }
    }
  `,
  strategy: `
    objectives
    why
    grades
    strategyPartOne {
      json
    }
    strategyPartTwo {
      json
    }
    authorCollection {
      items{
        authorName
      }
    }
    subscriptionCollection(limit: 10) {
      items {
        subscriptionCategory
      }
    }
  `,
  course: `
    itemDescription{
      json
    }
    minCompletionTime
    maxCompletionTime
    credit
    objectives
    courseUrl
    thoughtIndustriesCourseIds
    surveyLink
    subject
    subscriptionCollection(limit: 10) {
      items {
        subscriptionCategory
      }
    }
    authorCollection {
      items{
        authorName
      }
    }
  `,
  learnAbout: `
    itemDescription
    learnAboutContent {
      ${richTextFieldsGQL}
    }
  `,
  contentPreview: `
    itemDescription
    releaseDate
    marketingContent {
     ${richTextFieldsGQL}
    }
  `,
  // Activty Types
  activityTheme: `
    ${globalActivityFields}
    ${globalActivityRichTextFields}
    activityBanner {
      url
    }
    itemTagline
    surveyLink
    activityTheme
    activityType
    numberOfKids
    academicConnections
    academicConnectionsNotes
    beforeYouStartTitle
  `,
  activityConnections: `
    ${globalActivityFields}
    ${globalActivityRichTextFields}
    activityTheme
  `,
  activityDiscussions: `
    ${globalActivityFields}
    ${globalActivityRichTextFields}
    activityTheme
  `,
  activityFitness: `
    ${fitnessContentFields}
    setupTeacher {
      ${richTextFieldsGQL}
    }
    videosCollection {
      items {
        ${mediaVideoResourceFragment}
      }
    }
    relatedBlastsCollection {
      items {
        __typename,
        ${relatedTypesMetaFragment}
      }
    }
  `,
  activityLifeSkills: `
    ${globalActivityFields}
    ${globalActivityRichTextFields}
    surveyLink
    activityTheme
    numberOfKids
  `,
  activityServiceLearning: `
    ${globalActivityFields}
    ${globalActivityRichTextFields}
    surveyLink
    numberOfKids
  `,
  activityBlast: `
    itemDescription
    grades
    numberOfKids
    completionTime
    gameDirections
    gameSteps {
      ${richTextFieldsGQL}
    }
    videosCollection {
      items {
        ${mediaVideoResourceFragment}
      }
    }
  `
};


const relatedTypesFragment = `
  items {
    __typename
    ...on Course {
      ${relatedTypesMetaFragment}
      minCompletionTime
      maxCompletionTime
    }
    ...on ActivityConnections {
      ${relatedTypesMetaFragment}
    }
    ...on ActivityDiscussions {
      ${relatedTypesMetaFragment}
    }
    ...on ActivityLifeSkills {
      ${relatedTypesMetaFragment}
    }
    ...on ActivityServiceLearning {
      ${relatedTypesMetaFragment}
    }
    ...on ActivityTheme {
      ${relatedTypesMetaFragment}
    }
    ...on ActivityBlast {
      ${relatedTypesMetaFragment}
    }
    ...on ActivityFitness {
      ${relatedTypesMetaFragment}
    }
    ...on MediaResource {
      ${relatedTypesMetaFragment}
      itemDescription
      file {
        url
      }
    }
    ...on MediaResourceVideo {
      sys {
        id
      },
      itemThumbnailLogo {
        url
      }
      itemTitle,
      itemDescription: itemShortDescription,
      completionTimeMinutes,
      completionTimeSeconds,
      grades,
      tags,
      file {
        url
      }
    }
    ...on MediaResourceLong {
      ${relatedTypesMetaFragment}
    }
    ...on Strategy {
      ${relatedTypesMetaFragment}
    }
    ...on Routine {
      ${relatedTypesMetaFragment}
    }
    ...on LearnAbout {
      ${relatedTypesMetaFragment}
    }
    ...on ContentPreview {
      ${relatedTypesMetaFragment}
    }
  }
`;

// Fragment for related
const relatedItemsFragment = `
  relatedContentCollection {
    ${relatedTypesFragment}
  }
`;

const additionalResourcesFragment = `
  relatedResourcesCollection {
    ${relatedTypesFragment}
  }
`;

const relatedVideoItemsFragment = `
  relatedContentCollection {
    ${relatedTypesFragment}
  }
`;

// Get landing page display data
export const getContentfulGraphQLQuery = (contentType: string, contentId: String) => {
  const isPreview = isPreviewUrl();
  const query = `
    query {
      ${contentType}(id: "${contentId}", preview: ${isPreview}) {
        ${globalContentFields}
        ${contentTypesFragments[contentType as keyof typeof contentTypesFragments]}
      }
    }
  `;
  return query;
};

// Get Activity Theme Rich Text Fields (Too complex for a single GQL Query)
export const getActivityRichTextFieldsGraphQLQuery = (contentType: string, contentId: String) => {
  const query = `
    query {
      ${contentType}(id: "${contentId}") {
          sys {
            id
          }
          ${contentType == 'activityTheme' ? activityThemeRichTextFields : ''}
      }
    }
  `;
  return query;
};

// Get related content data
export const getRelatedContentfulGraphQLQuery = (contentType: string, contentId: String, getAdditionalResources: boolean) => {
  const isPreview = isPreviewUrl();
  const query = `{
    ${contentType}(id: "${contentId}", preview: ${isPreview}) {
      ${relatedItemsFragment}
      ${getAdditionalResources ? additionalResourcesFragment : ''}
    }
  }`;
  return query;
};

export const getVideoContentGraphQLQuery = (contentId: String) => {
  const isPreview = isPreviewUrl();
  const query = `
    query {
      mediaResourceVideo(id: "${contentId}", preview: ${isPreview}) {
        __typename,
        sys {
          id
        },
        itemTitle,
        itemShortDescription
        itemDescription{
          json
        },
        completionTimeMinutes,
        completionTimeSeconds,
        grades,
        tags,
        file {
            url
        },
        captionFile {
          url
        }
      }
    }
  `;
  return query;
};

export const getRelatedVideoContentfulGraphQLQuery = (
  contentType: string,
  contentId: String
) => {
  const query = `{
    ${contentType}(id: "${contentId}") {
      ${relatedVideoItemsFragment}
    }
  }`;
  return query;
};


function isPreviewUrl() {
  const hash = window.location.hash;
  const params = new URLSearchParams(hash.slice(hash.indexOf("?")));
  return params.get("preview") === "true" ? 'true' : 'false';
}
